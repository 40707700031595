import {BattleGameModes} from '@cssbattle/shared/battles'
import styles from './BattleModeInfoPill.module.css'
import {HStack, VStack} from '@components/Stack'
import {Icon} from './Icon'
import {VersusGameModes} from '@cssbattle/shared/versus'

function BattleModeInfoPill({
  gameMode,
  minimal = false,
  onClose,
  isVersus = false,
}) {
  if (isVersus) {
    if (minimal) {
      return (
        <div className="pill">
          <span className="pill__emoji">{VersusGameModes[gameMode].emoji}</span>{' '}
          {VersusGameModes[gameMode].label}
        </div>
      )
    }

    return (
      <div
        className={`${styles.battleMode} ${styles.battleModeLarge} ${styles.versusBattleMode}`}
      >
        <HStack>
          <p className={styles.gameModeLabel}>
            {VersusGameModes[gameMode].emoji}
          </p>
          <VStack gap="0.25rem" align="flex-start">
            {VersusGameModes[gameMode].label}
            <p className={styles.gameModeLabelDesc}>
              {VersusGameModes[gameMode].description}
            </p>
          </VStack>
        </HStack>

        {onClose && (
          <a className={styles.closeBtn} onClick={onClose}>
            <Icon name="cross" marginRight="0" size="18" />
          </a>
        )}
      </div>
    )
  } else {
    if (minimal) {
      return (
        <div className="pill">
          {gameMode === BattleGameModes.FIRST_TO_MATCH ? (
            <>
              <span className="pill__emoji">⏰</span> Time Attack
            </>
          ) : (
            <>
              <span className="pill__emoji">⛳</span> Code Golf
            </>
          )}
        </div>
      )
    }

    return (
      <div className={`${styles.battleMode} ${styles.battleModeLarge}`}>
        {gameMode === BattleGameModes.FIRST_TO_MATCH ? (
          <HStack>
            <p className={styles.gameModeLabel}>⏰</p>
            <VStack gap="0.25rem" align="flex-start">
              Time Attack
              <p className={styles.gameModeLabelDesc}>
                Quickest to get 100% matches wins the game
              </p>
            </VStack>
          </HStack>
        ) : (
          <HStack>
            <p className={styles.gameModeLabel}>⛳</p>
            <VStack gap="0.25rem" align="flex-start">
              Code Golf
              <p className={styles.gameModeLabelDesc}>
                Shortest code with a 100% match gets the highest score
              </p>
            </VStack>
          </HStack>
        )}

        {onClose && (
          <a className={styles.closeBtn} onClick={onClose}>
            <Icon name="cross" marginRight="0" size="18" />
          </a>
        )}
      </div>
    )
  }
}

export {BattleModeInfoPill}
