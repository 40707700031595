import React, {useState} from 'react'
import {useRouter} from 'next/router'
import {Button} from 'src/Button'
import {Modal} from '@components/Modal.jsx'
import {ButtonGroup, Input, Box} from 'src/Common'
import {Icon} from 'src/Icon'
import {saveBattle} from 'src/util/db'
import {useFormInput} from 'src/hooks/useFormInput'
import {useUser} from 'src/hooks/useUser'
import {Text} from '@components/Text'
import {VStack} from '@components/Stack'

const CreateBattleModal = ({isOpen, onRequestClose}) => {
  const router = useRouter()
  const battleNameInput = useFormInput('An awesome battle')
  const [isSaving, setIsSaving] = useState(false)
  const user = useUser()

  function submitHandler(e) {
    e.preventDefault()
    setIsSaving(true)
    saveBattle({
      userId: user.uid,
      battle: {
        name: battleNameInput.value,
        duration: 30,
      },
      levels: [],
    }).then((id) => {
      setIsSaving(false)
      // router.push(`/battle/create/${id}`)
      location.href = `/battle/create/${id}`
    })
  }
  return (
    <Modal isOpen={isOpen} size="content" extraClasses="modal--text-center">
      <div style={{margin: '1rem 0 0.2rem'}}>
        <Icon name="battle" size="48" />
      </div>
      <h2 style={{marginBottom: '2rem'}}>Host a Battle</h2>
      {/* <Box style={{maxWidth: '60ch'}}> */}
      <VStack gap="0.5rem">
        <Text tag="p" size={1} lineHeight="1.6">
          Hosting a conference, meetup, company engineering event or just want
          to have some nerd fun?{' '}
          <a
            href="/blog/introducing-custom-battles"
            className="link"
            target="_blank"
          >
            Read more about Custom Battles
          </a>
          .
        </Text>
        <Text tag="p" size={1} lineHeight="1.6">
          Create your own battles with custom targets, leaderboards, and,
          duration. Enter a nice name for your battle to proceed:
        </Text>
      </VStack>
      {/* </Box> */}
      <Box style={{marginTop: '2.5rem'}}>
        <form onSubmit={submitHandler}>
          <Input label="Battle name" {...battleNameInput.input} required />

          <ButtonGroup center>
            <Button onClick={onRequestClose}>Cancel</Button>

            <Button type="submit" isLoading={isSaving} primary>
              Configure Battle
            </Button>
          </ButtonGroup>
        </form>
      </Box>
    </Modal>
  )
}

export {CreateBattleModal}
